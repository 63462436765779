import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import CryptoJS from "crypto-js";
import * as config from "configure/configServer.js";

import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import "react-leaflet-markercluster/dist/styles.min.css"; // sass

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Card from "components/Card/Card.js";
import InputLabel from "@material-ui/core/InputLabel";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Pie } from "react-chartjs-2";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  API_get_prov,
  API_no_project,
  API_get_pointOnMap,
} from "configure/configServer.js";

import "leaflet-switch-scale-control";
import "leaflet-switch-scale-control/src/L.Control.SwitchScaleControl.css";
import "leaflet.locatecontrol";
import "chartjs-plugin-datalabels";

const chartExample5 = {
  options: {
    responsive: true,
    elements: {
      center: {
        text: "100%",
      },
    },
    legend: {
      display: true,
      position: "bottom",
      align: "middle",
      labels: {
        fontStyle: "bold",
        fontSize: 14,
        fontColor: "black",
        usePointStyle: true,
      },
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          return " " + data["labels"][tooltipItem["index"]];
        },
      },
    },
    plugins: {
      datalabels: {
        color: "white",
        display: function(ctx) {
          return ctx.dataset.data[ctx.dataIndex];
        },
        font: {
          weight: "bold",
        },
        offset: 0,
        padding: 0,
      },
      aspectRatio: 3 / 2,
      cutoutPercentage: 8,
      layout: {
        padding: 16,
      },
      elements: {
        line: {
          fill: false,
        },
        point: {
          hoverRadius: 7,
          radius: 5,
        },
      },
    },
  },
};

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      province: [],
      selected_prov: 0,
      value: 0,
      path: require("assets/img/icon/HR.png"),
      bbox_layer: L.layerGroup(),
      markerLayer: L.layerGroup(),
      error: false,
      noti: false,
      key: undefined,
      storeData: "",
      bbox_data: "",
      project_approved: 0,
      project_rejected: 0,
      project_wait: 0,
      icon: {
        house: {
          green: require("assets/img/icon/HG.png"),
          yellow: require("assets/img/icon/HY.png"),
          red: require("assets/img/icon/HR.png"),
        },
        condo: {
          green: require("assets/img/icon/CG.png"),
          yellow: require("assets/img/icon/CY.png"),
          red: require("assets/img/icon/CR.png"),
        },
        villa: {
          green: require("assets/img/icon/VG.png"),
          yellow: require("assets/img/icon/VY.png"),
          red: require("assets/img/icon/VR.png"),
        },
      },
    };
  }

  requestToken = () => {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("sessionP");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.setState({
            token: jsonDec.token,
            member_id: jsonDec.member_id,
            member_username: jsonDec.member_username,
            member_org: jsonDec.member_org,
          });
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  handleChange = (value) => {
    this.setState({ value });
  };
  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  genMarker = (url) => {
    let myIcon = L.icon({
      iconUrl: url,
      iconSize: [40, 40],
      shadowSize: [45, 40],
      iconAnchor: [20, 20],
      popupAnchor: [0, -10],
    });
    return myIcon;
  };

  getUrlIcon = (status, asset_type) => {
    let self = this;
    let url = null;
    switch (status) {
      case 1:
        url = self.state.icon[asset_type].red;
        break;
      case 2:
        url = self.state.icon[asset_type].green;
        break;
      case 3:
        url = self.state.icon[asset_type].lightgreen;
        break;
      case 4:
        url = self.state.icon[asset_type].blue;
        break;
      case 5:
        url = self.state.icon[asset_type].yellow;
        break;
      case 6:
        url = self.state.icon[asset_type].pink;
        break;
      default:
        url = self.state.icon[asset_type].green;
        break;
    }
    return url;
  };

  genPopupContent = (feature) => {
    let adddress =
      "<h4>ต." +
      feature.properties.tambon_t +
      " อ." +
      feature.properties.amphoe_t +
      " จ." +
      feature.properties.province_t +
      "</h4>";
    let status = "<h4>" + feature.properties.project_status + "</h4>";
    let popupContent =
      "<h4><b>ชื่อโครงการ: </b>" +
      feature.properties.nameth +
      "</h4>" +
      "<h4><b>รหัสโครงการ: </b>" +
      feature.properties.refcode +
      "</h4>" +
      adddress +
      status;

    return popupContent;
  };

  updateMarkerOnMap(rawdata, type) {
    let self = this;
    let data = L.geoJSON(JSON.parse(rawdata), {
      pointToLayer: function(feature, latlng) {
        let approved = feature.properties.approved;
        // console.log(approved);
        if (type === 1) {
          if (approved === 1) {
            return L.marker(latlng, {
              icon: self.genMarker(self.state.icon.house.green),
            });
          } else if (approved === 2) {
            return L.marker(latlng, {
              icon: self.genMarker(self.state.icon.house.red),
            });
          } else if (approved === 3) {
            return L.marker(latlng, {
              icon: self.genMarker(self.state.icon.house.yellow),
            });
          }
        } else if (type === 2) {
          if (approved === 1) {
            return L.marker(latlng, {
              icon: self.genMarker(self.state.icon.condo.green),
            });
          } else if (approved === 2) {
            return L.marker(latlng, {
              icon: self.genMarker(self.state.icon.condo.red),
            });
          } else if (approved === 3) {
            return L.marker(latlng, {
              icon: self.genMarker(self.state.icon.condo.yellow),
            });
          }
        } else if (type === 3) {
          if (approved === 1) {
            return L.marker(latlng, {
              icon: self.genMarker(self.state.icon.villa.green),
            });
          } else if (approved === 2) {
            return L.marker(latlng, {
              icon: self.genMarker(self.state.icon.villa.red),
            });
          } else if (approved === 3) {
            return L.marker(latlng, {
              icon: self.genMarker(self.state.icon.villa.yellow),
            });
          }
        }
      },
      onEachFeature: function(feature, layer) {
        if (feature.properties && feature.properties) {
          return layer.bindPopup(self.genPopupContent(feature));
        }
      },
    });

    this.state.markerLayer.addLayer(data);
  }

  getPointForMap = () => {
    let self = this;
    let data = {
      org_id: self.state.member_org,
      type: self.state.type,
      provcode: self.state.selected_prov,
    };

    var request = new Request(API_get_pointOnMap, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: this.state.token,
      }),
      body: JSON.stringify(data),
    });
    fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        if (parseInt(data.status) === 200) {
          let featureCollection = JSON.stringify(data.jsonb_build_object);
          self.updateMarkerOnMap(featureCollection, data.type);
        }
      });
  };

  getNoOfProjects = () => {
    let self = this;
    let data = {
      org_id: self.state.member_org,
      type: self.state.type,
      provcode: self.state.selected_prov,
    };

    // console.log(data);

    var request = new Request(API_no_project, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token,
      }),
      body: JSON.stringify(data),
    });
    fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        self.state.markerLayer.clearLayers();

        let info = data;
        if (parseInt(info.status) === 200) {
          this.setState({
            project_approved: parseInt(info.project_approved),
            project_rejected: parseInt(info.project_rejected),
            project_wait: parseInt(info.project_wait),
          });
          self.getPointForMap();
        }
      });
  };

  getProvince = () => {
    let data = {
      pro_id: 0,
      amp_id: 0,
      tam_id: 0,
    };
    var request = new Request(API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(data),
    });
    fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.code === 200) {
          let prov = data.data;
          prov = prov.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          );
          this.setState({
            province: prov,
          });
        }
      });
  };

  componentDidMount() {
    let self = this;
    self.requestToken();
    self.getProvince();

    let osm_map = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 19,
      minZoom: 0,
    });

    // let url_heremap =
    //   "https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png?app_id=" +
    //   config.heremap_appID +
    //   "&app_code=" +
    //   config.heremap_appCode;

    // let url_heremap = `https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?apiKey=${config.heremap_API_key}&ppi=320`;

    let url_heremap = "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}";

    let sattelite = L.tileLayer(url_heremap, {
      attribution:
        '&copy; <a href="https://www.here.com">HERE Technologies | HERE </a>',
      maxZoom: 19,
      minZoom: 0,
    });

    self.map = L.map(self.refs.map, {
      layers: [sattelite],
    }).setView([13, 100], 6);

    let baseMaps = {
      // eslint-disable-next-line prettier/prettier
      ภาพดาวเทียม: sattelite,
      // eslint-disable-next-line prettier/prettier
      OpenStreetMap: osm_map,
    };

    self.state.markerLayer.addTo(self.map);

    self.state.bbox_layer.addTo(self.map);

    self.map.addControl(
      new L.Control.SwitchScaleControl({
        ratioCustomItemText: "1: ระบุเลข scale",
        updateWhenIdle: true,
        scales: [
          1000,
          2500,
          5000,
          10000,
          20000,
          40000,
          70000,
          140000,
          300000,
          600000,
          1200000,
          2300000,
        ],
      })
    );

    L.control.locate().addTo(self.map);

    L.control.layers(baseMaps).addTo(self.map);
  }
  handleSelectProvince = (event) => {
    this.setState(
      {
        selected_prov: event.target.value,
      },
      () => {
        this.getNoOfProjects();
      }
    );
  };
  handleSelectedAssetType = (event) => {
    this.setState(
      {
        type: event.target.value,
      },
      () => {
        this.getNoOfProjects();
      }
    );
  };
  render() {
    return (
      <Card style={{ marginTop: "-1%" }}>
        <CardBody>
          <GridContainer justify="center">
            <GridItem xs={9} md={9}>
              <div
                // eslint-disable-next-line react/no-string-refs
                ref="map"
                style={{
                  height: "80vh",
                  width: "100%",
                  position: "relative",
                  borderRadius: "6px",
                }}
              />
            </GridItem>
            <GridItem xs={3} md={3}>
              <h4>รายงานแบบสอบถาม</h4>
              <InputLabel id="asset-type">ประเภทอสังหาริมทรัพย์</InputLabel>
              <Select
                value={this.state.type}
                onChange={this.handleSelectedAssetType}
              >
                <MenuItem value={0}>เลือกประเภท</MenuItem>
                <MenuItem value={1}>บ้านจัดสรร</MenuItem>
                <MenuItem value={2}>อาคารชุด</MenuItem>
                <MenuItem value={3}>บ้านพักตากอากาศ</MenuItem>
              </Select>
              <Pie
                data={{
                  labels: [
                    this.state.project_approved + " ผ่าน",
                    this.state.project_rejected + " ไม่ผ่าน",
                    this.state.project_wait + " รอตรวจ",
                  ],
                  datasets: [
                    {
                      backgroundColor: ["#17C700", "#f44336", "#FF9800"],
                      data: [
                        this.state.project_approved,
                        this.state.project_rejected,
                        this.state.project_wait,
                      ],
                      datalabels: {
                        labels: {
                          value: {
                            align: "center",
                            backgroundColor: "white",
                            borderColor: "white",
                            borderWidth: 2,
                            borderRadius: 4,
                            color: (ctx) => {
                              return ctx.dataset.backgroundColor;
                            },
                            formatter: (value, ctx) => {
                              let datasets = ctx.chart.data.datasets;
                              let sum = datasets[0].data.reduce(
                                (a, b) => a + b,
                                0
                              );
                              let percentage =
                                Math.round((value / sum) * 100) + "%";
                              // console.log(datasets, value, sum);
                              return percentage;
                            },
                            padding: 4,
                          },
                        },
                      },
                    },
                  ],
                }}
                options={chartExample5.options}
                width={80}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(dashboardStyle)(Report);
